import React from "react";
import { classNames } from "@/utils";
import withForwardRef, { ForwardRefProps } from "@/hoc/withForwardRef";
type AlignItems = "start" | "end" | "center" | "baseline" | "stretch" | "between";
type JustifyContent = "start" | "center" | "end" | "between" | "around" | "evenly";
type Padding = "none" | "small" | "medium" | "large";
interface Props {
  children: React.ReactNode;
  alignItems?: AlignItems;
  justifyContent?: JustifyContent;
  spaceBetween?: boolean;
  fullWidth?: boolean;
  gap?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  borderBottom?: boolean;
}
export interface ColumnProps extends Props {
  fullHeight?: boolean;
  className?: string;
}
export const Column = withForwardRef<HTMLDivElement, ForwardRefProps<HTMLDivElement> & ColumnProps & React.HTMLAttributes<HTMLDivElement>>((props): JSX.Element => {
  const {
    children,
    forwardedRef,
    fullHeight,
    alignItems,
    justifyContent,
    spaceBetween,
    className,
    ...rest
  } = props;
  const classes = classNames(className, "flex", "flex-col", props.gap && `gap-${props.gap}`, props.fullHeight && "flex-1", props.alignItems && `items-${props.alignItems}`, props.justifyContent && `justify-${props.justifyContent}`, props.spaceBetween && "justify-between", props.className);
  return <div {...rest} ref={forwardedRef} className={classes}>
      {children}
    </div>;
});
interface RowProps extends Props {
  fullWidth?: boolean;
  paddingY?: Padding;
  paddingX?: Padding;
}
export function Row({
  children,
  ...props
}: RowProps): JSX.Element {
  const classes = classNames("flex flex-row", props.gap && `gap-${props.gap}`, props.fullWidth && "w-full", props.alignItems && `items-${props.alignItems}`, props.justifyContent && `justify-${props.justifyContent}`, props.spaceBetween && "justify-between", props.borderBottom && "border-b border-textureBorderGray", props.paddingY === "medium" && "py-3", props.paddingX === "medium" && "px-4");
  return <div className={classes} data-sentry-component="Row" data-sentry-source-file="index.tsx">{children}</div>;
}
type GapProps = {
  px: number;
  rem?: never;
} | {
  px?: never;
  rem: number;
};
export function Gap(props: GapProps): JSX.Element {
  const gap = props.px ? `${props.px}px` : props.rem ? `${props.rem}rem` : "0px";
  return <div style={{
    height: gap
  }} data-sentry-component="Gap" data-sentry-source-file="index.tsx" />;
}
export function Hidden({
  children,
  on
}: React.PropsWithChildren<{
  on: "desktop" | "tablet" | "mobile";
}>): JSX.Element {
  const className = classNames("inline-block", on === "desktop" && "hidden sm:inline-block md:inline-block", on === "tablet" && "hidden sm:inline-block lg:inline-block", on === "mobile" && "hidden md:inline-block lg:inline-block");
  return <div className={className} data-sentry-component="Hidden" data-sentry-source-file="index.tsx">{children}</div>;
}
export function Grid({
  children,
  cols,
  rows,
  border,
  padded,
  gap
}: React.PropsWithChildren<{
  cols?: number;
  rows?: number;
  border?: boolean;
  padded?: boolean;
  gap?: number;
}>): JSX.Element {
  const className = classNames("grid", cols === 1 && "grid-cols-1", cols === 2 && "grid-cols-2", cols === 3 && "grid-cols-3", cols === 4 && "grid-cols-4", rows === 1 && "grid-rows-1", rows === 2 && "grid-rows-2", rows === 3 && "grid-rows-3", rows === 4 && "grid-rows-4", border && "border-t border-b border-textureBorderGray divide-x divide-textureBorderGray", gap && `gap-${gap}`);
  const childrenWithPadding = React.useMemo(() => {
    return padded ? React.Children.map(children, child => <div className="p-4">{child}</div>) : children;
  }, [children, padded]);
  return <div className={className} data-sentry-component="Grid" data-sentry-source-file="index.tsx">{childrenWithPadding}</div>;
}