import React from "react";
import { classNames } from "@/utils";
import { TabsProps, TabsVariant } from "./hooks/use-tabs";
export function Container(props: React.PropsWithChildren) {
  return <div data-sentry-component="Container" data-sentry-source-file="style.tsx">
      <div className="no-scrollbar overflow-x-auto overflow-y-hidden sm:flex sm:items-baseline">
        {props.children}
      </div>
    </div>;
}
export function TabHeaders({
  variant,
  children
}: React.PropsWithChildren<{
  variant?: TabsProps["variant"];
}>) {
  const className = classNames(variant === TabsVariant.Chart ? "flex p-1 items-start space-x-1 flex-grow-0 flex-shrink-0 flex-basis-0 rounded-[5px] bg-textureGlandhalf" : "-mb-px flex space-x-8");
  return <div className="mt-4 sm:mt-0" data-sentry-component="TabHeaders" data-sentry-source-file="style.tsx">
      <nav className={className}>{children}</nav>
    </div>;
}
export function TabHeader({
  isSelected,
  variant,
  ...props
}: React.PropsWithChildren<{
  onClick: React.MouseEventHandler;
  isSelected: boolean;
  variant?: TabsProps["variant"];
}>) {
  let className = classNames(isSelected ? "border-texturePennBlue border-b-2" : "", "cursor-pointer whitespace-nowrap text-texturePennBlue py-[8px]");
  if (variant === TabsVariant.Chart) {
    className = classNames(isSelected ? "bg-textureNeutralGray" : "", "flex py-[6px] px-[16px] justify-center items-center space-x-2 self-stretch rounded-md cursor-pointer");
  }
  return <span {...props} className={className} aria-current={isSelected ? "page" : undefined} data-sentry-component="TabHeader" data-sentry-source-file="style.tsx">
      {props.children}
    </span>;
}
export function TabContent({
  isSelected,
  ...props
}: React.PropsWithChildren<{
  isSelected: boolean;
}>) {
  return <div {...props} className={classNames(isSelected ? "block" : "hidden", "mt-[2.5rem]")} data-sentry-component="TabContent" data-sentry-source-file="style.tsx">
      {props.children}
    </div>;
}