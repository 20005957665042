"use client";

import React from "react";
import { classNames } from "@/utils";
import { TextProps } from "@/components/Typography/Text";
export function useText(props: TextProps, additionalClassName?: string) {
  const className = classNames(additionalClassName, textClassNames(props));
  const letterSpacing = getLetterSpacing(props);
  function renderChildren() {
    if (Array.isArray(props.children)) {
      /* If the Text is containing for example content similar like this:
        <Text.PennBlue extraSmall>
          {t("Already have an account?")}
          <Text.PennBlue extraSmall semiBold onClick={navigateToSignIn}>
            {t("Sign in")}
          </Text.PennBlue>
        </Text.PennBlue>
       It will prepend the &nbsp; before react component
       <Text.PennBlue extraSmall>
          {t("Already have an account?")}
           &nbsp;
          <Text.PennBlue extraSmall semiBold onClick={navigateToSignIn}>
            {t("Sign in")}
          </Text.PennBlue>
        </Text.PennBlue>
       */
      return React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return <>&nbsp;{child}&nbsp;</>;
        } else {
          return child;
        }
      });
    }
    return props.children;
  }
  return {
    onClick: props.onClick,
    children: renderChildren(),
    className,
    letterSpacing
  };
}
function getLetterSpacing(props: TextProps) {
  const sizeToLetterSpacingMap = {
    inter: {
      large: "-0.25%",
      small: "-0.25%",
      extraSmall: "0"
    },
    epilogue: {
      large: "0",
      small: "-0.25%",
      extraSmall: "-0.25%"
    }
  };
  const letterSpacing = props.inter ? sizeToLetterSpacingMap.inter : sizeToLetterSpacingMap.epilogue;
  const appliedSizeProp = Object.keys(letterSpacing).find(key => props[key]) ?? "normal";
  return letterSpacing[appliedSizeProp];
}
function textClassNames(props: TextProps) {
  return classNames(
  // for now, we need to handle icons inside which share colors
  /*"flex flex-row items-center gap-1",*/
  // overflow, all this is needed because the tag is span and not div
  props.ellipsis && "text-ellipsis overflow-hidden whitespace-nowrap inline-block max-w-full",
  // Font
  props.inter ? "font-inter" : "font-epilogue",
  // Size
  "text-base leading-6",
  //16px
  props.extraLarge && "text-custom-extra-large leading-custom-extra-large", props.large && "text-lg leading-6",
  //18px
  props.small && "text-sm leading-6",
  //14px
  props.extraSmall && "text-xs leading-4",
  //12px
  // Mobile size
  props["onMobile:extraLarge"] && "sm:text-custom-extra-large sm:leading-custom-extra-large", props["onMobile:large"] && "sm:text-lg sm:leading-6", props["onMobile:regular"] && "sm:text-base sm:leading-6", props["onMobile:small"] && "sm:text-sm sm:leading-6", props["onMobile:extraSmall"] && "sm:text-xs sm:leading-4",
  // Tablet size
  props["onTablet:extraLarge"] && "md:text-custom-extra-large md:leading-custom-extra-large", props["onTablet:large"] && "md:text-lg md:leading-6", props["onTablet:regular"] && "md:text-base md:leading-6", props["onTablet:small"] && "md:text-sm md:leading-6", props["onTablet:extraSmall"] && "md:text-xs md:leading-4",
  //Weight
  "font-normal", props.semiBold && "font-semibold", props.medium && "font-medium", props.onClick && "cursor-pointer", props.underline && "border-b border-current", props.alignCenter && "text-center", props.upperCase && "uppercase");
}