import React from "react";
import { twMerge } from "tailwind-merge";
interface SkeletonProps {
  width?: string;
  height?: string;
  className?: string;
}
const Skeleton: React.FC<SkeletonProps> = ({
  width = "w-full",
  height = "h-full",
  className
}) => {
  return <div role="status" aria-label="Loading..." aria-busy="true" className={twMerge("animate-pulse bg-gradient-pulse", width, height, className)} data-sentry-component="Skeleton" data-sentry-source-file="Skeleton.tsx"></div>;
};
export { Skeleton };