import React from "react";
import { Container, ContentSection } from "./styles";
import { EmptyStateIcon } from "@/components/EmptyStates/icons/EmptyStateIcon";
export function EmptyState(props: React.PropsWithChildren<{
  className?: string;
}>) {
  return <Container className={props.className} data-sentry-element="Container" data-sentry-component="EmptyState" data-sentry-source-file="index.tsx">
      <ContentSection data-sentry-element="ContentSection" data-sentry-source-file="index.tsx">
        <EmptyStateIcon data-sentry-element="EmptyStateIcon" data-sentry-source-file="index.tsx" />
        {props.children}
      </ContentSection>
    </Container>;
}