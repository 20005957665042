import useTabs, { TabsProps, TabProps, TabsVariant } from "@/components/Tabs/hooks/use-tabs";
import { Container, TabHeader, TabHeaders, TabContent } from "./style";
import { Text } from "@/components/Typography";
interface TabsComponent extends React.FC<TabsProps> {
  Tab: React.FC<TabProps>;
}
const TabsWithoutLocationProvider: React.FC<TabsProps> = ({
  title,
  variant,
  ...props
}) => {
  const {
    tabs,
    selectTab,
    shouldNotRenderTab,
    isTabSelected
  } = useTabs(props);
  return <>
      <Container data-sentry-element="Container" data-sentry-source-file="Tabs.tsx">
        <TabHeaders variant={variant} data-sentry-element="TabHeaders" data-sentry-source-file="Tabs.tsx">
          {tabs.map(tab => <TabHeader key={tab.name} onClick={() => selectTab(tab)} isSelected={isTabSelected(tab)} variant={variant}>
              <Text.PennBlue extraSmall={variant === TabsVariant.Chart}>
                {tab.name}
              </Text.PennBlue>
            </TabHeader>)}
        </TabHeaders>
      </Container>
      {tabs.map(tab => {
      if (shouldNotRenderTab(tab)) {
        return null;
      }
      return <TabContent key={tab.name} isSelected={isTabSelected(tab)}>
            {tab.children}
          </TabContent>;
    })}
    </>;
};
const Tabs: TabsComponent = props => {
  return <TabsWithoutLocationProvider {...props} data-sentry-element="TabsWithoutLocationProvider" data-sentry-component="Tabs" data-sentry-source-file="Tabs.tsx" />;
};
Tabs.Tab = Tab;
function Tab(props: TabProps) {
  return props.children;
}
export default Tabs;