import { RequireOnlyOne } from "@9yco/utils.utils/types";
import { useText } from "@/components/Typography/Text/hooks/useText";
export type TextProps = TextSize & TextWeight & TextFont & React.HTMLAttributes<HTMLSpanElement> & {
  underline?: boolean;
  ellipsis?: boolean;
  alignCenter?: boolean;
  upperCase?: boolean;
};

// Normal is default
type TextSize = RequireOnlyOne<{
  extraLarge: boolean;
  large: boolean;
  small: boolean;
  extraSmall: boolean;
}> & OnMobileTextSize & OnTabletTextSize;
type OnMobileTextSize = RequireOnlyOne<{
  "onMobile:extraLarge": boolean;
  "onMobile:large": boolean;
  "onMobile:regular": boolean;
  "onMobile:small": boolean;
  "onMobile:extraSmall": boolean;
}>;
type OnTabletTextSize = RequireOnlyOne<{
  "onTablet:extraLarge": boolean;
  "onTablet:large": boolean;
  "onTablet:regular": boolean;
  "onTablet:small": boolean;
  "onTablet:extraSmall": boolean;
}>;
type TextWeight = RequireOnlyOne<{
  semiBold: boolean;
  /*normal: boolean;*/
  medium: boolean;
}>;
type TextFont = {
  inter?: boolean;
};
function TextPennBlue(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-texturePennBlue");
  return <span {...textProps} data-sentry-component="TextPennBlue" data-sentry-source-file="index.tsx" />;
}
function TextGray(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-textureInputCount");
  return <span {...textProps} data-sentry-component="TextGray" data-sentry-source-file="index.tsx" />;
}
function TextSoftLavender(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-textureSoftLavender");
  return <span {...textProps} data-sentry-component="TextSoftLavender" data-sentry-source-file="index.tsx" />;
}
function TextBlueGray(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-textureBlueGray");
  return <span {...textProps} data-sentry-component="TextBlueGray" data-sentry-source-file="index.tsx" />;
}
function TextDarkIndigo(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-textureDarkIndigo");
  return <span {...textProps} data-sentry-component="TextDarkIndigo" data-sentry-source-file="index.tsx" />;
}
function TextSuccess(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-textureGreen");
  return <span {...textProps} data-sentry-component="TextSuccess" data-sentry-source-file="index.tsx">{props.children}</span>;
}
export function TextError(props: React.PropsWithChildren<TextProps>) {
  const textProps = useText(props, "text-textureRed");
  return <span {...textProps} data-sentry-component="TextError" data-sentry-source-file="index.tsx">{props.children}</span>;
}
export const Text = {
  PennBlue: TextPennBlue,
  Gray: TextGray,
  SoftLavender: TextSoftLavender,
  BlueGray: TextBlueGray,
  DarkIndigo: TextDarkIndigo,
  Success: TextSuccess,
  Error: TextError
};