"use client";

import React from "react";
import { useTranslations } from "next-intl";
import { AreaChart } from "@/edges/ui/AreaChart";
import { ChartCard } from "@/edges/ui/ChartCard";
import { useFetchEnergyTotalStorage } from "@/services/metricsService";
function EnergyStorageTab() {
  const t = useTranslations("HomePage");
  const {
    title,
    data,
    props: chartProps
  } = useFetchEnergyTotalStorage({});
  return <ChartCard {...chartProps} title={title} data-sentry-element="ChartCard" data-sentry-component="EnergyStorageTab" data-sentry-source-file="index.tsx">
      <AreaChart data={data} xLabel="Time" yLabel="kWh" yFormatType="decimal" data-sentry-element="AreaChart" data-sentry-source-file="index.tsx" />
    </ChartCard>;
}
export default EnergyStorageTab;