"use client";

import React from "react";
import { useTranslations } from "next-intl";
import { AreaChart } from "@/edges/ui/AreaChart";
import { ChartCard } from "@/edges/ui/ChartCard";
import { useFetchEnergyTotalCapacity } from "@/services/metricsService";
function EnergyCapacityTab() {
  const t = useTranslations("HomePage");
  const {
    data,
    title,
    props: chartProps
  } = useFetchEnergyTotalCapacity({});
  return <ChartCard {...chartProps} title={title} data-sentry-element="ChartCard" data-sentry-component="EnergyCapacityTab" data-sentry-source-file="index.tsx">
      <AreaChart data={data} xLabel="Time" yLabel="kWh" yFormatType="decimal"
    // yFormatType="kWh"
    data-sentry-element="AreaChart" data-sentry-source-file="index.tsx" />
    </ChartCard>;
}
export default EnergyCapacityTab;