import { HeadingProps } from "@/components/Typography/Heading";
import { classNames } from "@/utils";

export function useHeading(props: HeadingProps, additionalClassName?: string) {
  const className = headingClassNames(props);

  const letterSpacing = getLetterSpacing(props);

  return {
    className: classNames(className, additionalClassName),
    letterSpacing,
  };
}

function headingClassNames(props: HeadingProps) {
  // Fucking tailwind for some reason does not override custom classes
  const noSizeProp =
    ["extraLarge", "large", "small", "extraSmall", "extraExtraSmall"].some(
      (size) => props[size],
    ) === false;

  return classNames(
    // Color
    props.color || "text-texturePennBlue",
    // Font
    "font-epilogue",
    // Size
    noSizeProp && "text-custom-medium leading-custom-medium",
    props.extraLarge && "text-custom-extra-large leading-custom-extra-large",
    props.large && "text-custom-large leading-custom-large",
    props.small && "text-custom-small leading-custom-small",
    props.extraSmall && "text-custom-extra-small leading-custom-extra-small",
    props.extraExtraSmall && "text-xs leading-[14px]",
    //Weight
    !props.semiBold && !props.medium && "font-normal",
    props.semiBold && "font-semibold",
    props.medium && "font-medium",
    // overflow, all this is needed because the tag is span and not div
    props.ellipsis &&
      "text-ellipsis overflow-hidden whitespace-nowrap inline-block max-w-full",

    // OnMobile size
    props["onMobile:extraLarge"] &&
      "sm:text-custom-extra-large sm:leading-custom-extra-large",
    props["onMobile:large"] && "sm:text-custom-large sm:leading-custom-large",
    props["onMobile:regular"] &&
      "sm:text-custom-medium sm:leading-custom-medium",
    props["onMobile:small"] && "sm:text-custom-small sm:leading-custom-small",
    props["onMobile:extraSmall"] &&
      "sm:text-custom-extra-small sm:leading-custom-extra-small",
    props["onMobile:extraExtraSmall"] && "sm:text-xs sm:leading-[14px]",
    // OnTablet: size
    props["onTablet::extraLarge"] &&
      "md:text-custom-extra-large md:leading-custom-extra-large",
    props["onTablet::large"] && "md:text-custom-large md:leading-custom-large",
    props["onTablet:regular"] &&
      "md:text-custom-medium md:leading-custom-medium",
    props["onTablet::small"] && "md:text-custom-small md:leading-custom-small",
    props["onTablet::extraSmall"] &&
      "md:text-custom-extra-small md:leading-custom-extra-small",
    props["onTablet::extraExtraSmall"] && "md:text-xs md:leading-[14px]",

    // Align
    props.alignCenter && "text-center",
    props.upperCase && "uppercase",
  );
}

function getLetterSpacing(props: HeadingProps) {
  const sizeToLetterSpacingMap = {
    extraLarge: "-0.25%",
    large: "-0.25%",
    normal: "0%",
    small: "0%",
    extraSmall: "0.25%",
  };

  const appliedSizeProp =
    Object.keys(sizeToLetterSpacingMap).find((key) => props[key]) ?? "normal";

  return sizeToLetterSpacingMap[appliedSizeProp];
}
