"use client";

import { Grid, Header, Content } from "./style";
import { Text } from "@/components/Typography";
import { useTranslations } from "next-intl";
import useTabs, { TabProps, TabsProps } from "@/components/Tabs/hooks/use-tabs";
import React from "react";
type HomePageTabs = React.FC<TabsProps> & {
  Tab: typeof Tab;
};
export const HomePageTabs: HomePageTabs = (props: TabsProps) => {
  const t = useTranslations();
  const {
    tabs,
    selectTab,
    shouldNotRenderTab,
    isTabSelected
  } = useTabs(props);
  return <Grid data-sentry-element="Grid" data-sentry-component="HomePageTabs" data-sentry-source-file="index.tsx">
      <Grid.Column data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <Text.DarkIndigo data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          {t("HomePage.getStarted")}
        </Text.DarkIndigo>
        {tabs.map(tab => <Header key={tab.name} label={tab.name} isSelected={isTabSelected(tab)} onClick={() => selectTab(tab)} />)}
      </Grid.Column>
      <Grid.Column data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        {tabs.map(tab => {
        if (shouldNotRenderTab(tab)) {
          return null;
        }
        return <Content key={tab.name} isSelected={isTabSelected(tab)}>
              {tab.children}
            </Content>;
      })}
      </Grid.Column>
    </Grid>;
};
function Tab(props: TabProps) {
  return props.children;
}
HomePageTabs.Tab = Tab;